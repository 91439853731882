<template>
  <div class="design-reward">
    <div class="main">
      <div class="block1"></div>
      <div class="design-wrap" id="top">
        <img src="../assets/img/reward/xuyan.png" alt="" class="notice" />

        <prize-content></prize-content>
      </div>
    </div>
    <div class="sidebar">
      <img class="img" src="../assets/img/reward/sidebar.png" />
      <div class="btns">
        <div class="block"></div>
        <a
          class="btn"
          v-for="i in Array.from({ length: 10 }).map((_, i) => i + 1)"
          :href="`#reward${i}`"
        ></a>
        <a class="btn" href="#top"></a>
        <div class="block"></div>
      </div>
    </div>
  </div>
</template>

<script>
import OtherReward from "../components/designReward/otherReward.vue";
import PrizeContent from "../components/designReward/PrizeContent.vue";
require("../assets/img/reward/xuyan.png");
export default {
  components: { PrizeContent, OtherReward },
  name: "designReward",
  setup() {
    return {};
  },
};
</script>

<style lang="less" scoped>
@font-face {
  font-family: "SS";
  src: url("../assets/fonts/SWEISPRINGCJKSC-BLACK.TTF") format("truetype");
}

@font-face {
  font-family: "SSB";
  src: url("../assets/fonts/SWEISPRINGCJKSC-BOLD.TTF") format("truetype");
}

@font-face {
  font-family: "SSP";
  src: url("../assets/fonts/SWEISPRINGSUGARCJKSC-BLACK.TTF") format("truetype");
}

@font-face {
  font-family: "SSPB";
  src: url("../assets/fonts/SWEISPRINGSUGARCJKSC-BOLD.TTF") format("truetype");
}

@font-face {
  font-family: "TCH";
  src: url("../assets/fonts/FZLTCHJW.TTF") format("truetype");
}

@font-face {
  font-family: "TH";
  src: url("../assets/fonts/FZLTHJW.TTF") format("truetype");
}

@font-face {
  font-family: "XH";
  src: url("../assets/fonts/FZLTXHJW.TTF") format("truetype");
}

.design-reward {
  display: flex;
  width: 100%;
  padding-left: calc(10.52vh);
  box-sizing: border-box;
  justify-content: center;
  >.sidebar {
    position: sticky;
    height: 100vh;
    top: 0;
    right: 5vw;
    > .img {
      height: 100%;
      object-fit: contain;
    }
    .btns {
      top: 0;
      left: 0;
      position: absolute;
      z-index: 1;
      height: 100%;
      width: 100%;
      > .block {
        height: 3.7%;
      }
      > .btn {
        display: block;
        height: 8.9%;
        width: 100%;
        // outline: 1px solid red;
        &:last-of-type {
          height: 5.5%;
        }
        &:first-of-type {
          height: 7%;
        }
        &:hover {
          cursor: pointer;
        }
        &:nth-of-type(-n + 10):hover {
          background: radial-gradient(
            rgba(255, 255, 255, 0.3),
            rgba(255, 255, 255, 0) 80%
          );
        }
      }
    }
  }
  >.main {
    flex: 9;
  }
  .design-wrap {
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    .notice {
      display: block;
      width: 733px;
      height: 374px;
    }
  }

  background-image: url("../assets/img/detailPic/bg.png") !important;
  background-size: cover;
  background-position: center;

  .block1 {
    height: 186px;
  }
}
</style>
