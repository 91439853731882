<template>
  <div class="prize-content">
    <div class="main-prize">
      <!-- 奖项1-3 -->
      <template v-for="(group, index) in commonRewards.slice(0, 3)">
        <div class="common-rewards" :id="`reward${index + 1}`">
          <img class="group-img" :src="group.groupImg" />
          <div class="reward" v-for="reward in group.rewards">
            <img :src="reward.rewardImg" alt="" class="reward-img" />
            <div class="works" :class="`works-${reward.works.length}`">
              <template v-if="reward.works.length" v-for="work in reward.works">
                <Work
                  v-if="work"
                  :workImg="work.workImg"
                  :workName="work.workName"
                  :authorImg="work.authorImg"
                  :authorName="work.authorName"
                  :id="work.id"
                  :isVideo="work.isVideo"
                  class="work"
                ></Work>
              </template>
              <NoRewardWork v-else></NoRewardWork>
            </div>
          </div>
        </div>
      </template>
      <!-- 奖项4 -->
      <div class="common-rewards mid" :id="`reward4`">
        <img
          src="../../assets/img/reward/part4/group.png"
          alt=""
          srcset=""
          class="group-img"
        />
        <a class="query-btn"  href="https://weibo.com/6242868635/4943727148665568" target="_blank">
          <img
            class="btn-img"
            src="../../assets/img/reward/part4/btn-text.png"
          />
        </a>
      </div>
      <!-- 奖项5-7 -->
      <template v-for="(group, index) in commonRewards.slice(3, 6)">
        <div class="common-rewards" :id="`reward${index + 5}`">
          <img class="group-img" :src="group.groupImg" />
          <div class="reward" v-for="reward in group.rewards">
            <img :src="reward.rewardImg" alt="" class="reward-img" />
            <div class="works" :class="`works-${reward.works.length}`">
              <template v-if="reward.works.length" v-for="work in reward.works">
                <Work
                  :workImg="work.workImg"
                  :workName="work.workName"
                  :authorImg="work.authorImg"
                  :authorName="work.authorName"
                  :id="work.id"
                  :isVideo="work.isVideo"
                  class="work"
                ></Work>
              </template>
              <NoRewardWork v-else></NoRewardWork>
            </div>
          </div>
        </div>
      </template>
      <!-- 奖项8 -->
      <div class="common-rewards mid" :id="`reward8`">
        <img
          src="../../assets/img/reward/part8/group.png"
          alt=""
          srcset=""
          class="group-img"
        />
        <a class="query-btn" href="https://weibo.com/6242868635/4943728374451458" target="_blank">
          <img
            class="btn-img"
            src="../../assets/img/reward/part8/btn-text.png"
          />
        </a>
      </div>
      <!-- 奖项9, 10 -->
      <div class="common-rewards" v-for="(group, index) in otherRewards">
        <img
          class="group-img"
          :src="group.groupImg"
          :id="`reward${index + 9}`"
        />
        <img
          :src="group.rewardImg"
          alt=""
          class="reward-img"
          style="margin: 26px auto 40px"
        />
        <div class="entities">
          <div class="entity" v-for="reward in group.entities">
            <div class="classification">【{{ reward.classification }}】</div>
            <div class="name">{{ reward.name }}</div>
          </div>
        </div>
      </div>
      <!-- 空白 -->
      <div class="space" style="height: 297px"></div>
    </div>
  </div>
</template>

<script>
import Work from "./Work.vue";
import NoRewardWork from "./NoRewardWork.vue";
import { getAwardList } from "../../utils/apis/rewards";
import { ref } from "vue";
export default {
  components: { Work, NoRewardWork },
  name: "PrizeContent",
  setup() {
    const commonRewards = ref([]);
    const otherRewards = ref([]);
    getAwardList().then((res) => {
      const { code, message, data } = res.data;
      if (code !== 200) {
        console.error("获取奖项列表失败:", message);
        alert(message);
        return;
      }
      const authorImg = "http://benqgroup.oss-cn-hangzhou.aliyuncs.com/design-competition/design-competition-resources/%E9%98%BF%E5%9F%BA%E7%8B%AE.png";
      /**
       * 参赛类别 1 设计类 2 动画类 3 CG类
       *
       */
      // private Integer category;
      /**
       * 参赛组别 1 专业组 2 高校组
       */
      // private Integer competitionUnit;
      /**
       * 获奖级别 1 一等奖 2 二等奖 3 三等奖 4 优秀奖 5 参与奖
       */
      // private Integer prize;
      const imageWidthMap = {
        1: 1880,
        2: 940,
        3: 282,
        4: 282,
        5: 282,
      };
      const getWorks = (competitionUnit, category, prize) => {
        return data
          .filter(
            (item) =>
              item.competitionUnit === competitionUnit &&
              item.category === category &&
              item.prize === prize
          )
          .map((item) => {
            let size = 0;
            if (competitionUnit === 2) {
              size = prize
            } else {
              size = 1
            }
            return {
              workImg: `${item.cover}?x-oss-process=image/resize,w_${imageWidthMap[size]}`,
              workName: item.name,
              authorImg,
              authorName: item.author,
              id: item.id,
              isVideo: category===2,
            };
          });
      };
      commonRewards.value = [
        // 高校 设计类
        {
          groupImg: require("../../assets/img/reward/part1/group.png"),
          rewards: [
            {
              rewardImg: require("../../assets/img/reward/part1/reward (1).png"),

              works: getWorks(2, 1, 1),
            },
            {
              rewardImg: require("../../assets/img/reward/part1/reward (2).png"),
              works: getWorks(2, 1, 2),
            },
            {
              rewardImg: require("../../assets/img/reward/part1/reward (3).png"),
              works: getWorks(2, 1, 3),
            },
            {
              rewardImg: require("../../assets/img/reward/part1/reward (4).png"),
              works: getWorks(2, 1, 4),
            },
          ],
        },
        // 高校 CG类
        {
          groupImg: require("../../assets/img/reward/part2/group.png"),
          rewards: [
            {
              rewardImg: require("../../assets/img/reward/part2/reward (1).png"),
              works: getWorks(2, 3, 1),
            },
            {
              rewardImg: require("../../assets/img/reward/part2/reward (2).png"),
              works: getWorks(2, 3, 2),
            },
            {
              rewardImg: require("../../assets/img/reward/part2/reward (3).png"),
              works: getWorks(2, 3, 3),
            },
            {
              rewardImg: require("../../assets/img/reward/part2/reward (4).png"),
              works: getWorks(2, 3, 4),
            },
          ],
        },
        // 高校 动画类
        {
          groupImg: require("../../assets/img/reward/part3/group.png"),
          rewards: [
            {
              rewardImg: require("../../assets/img/reward/part3/reward (1).png"),
              works: getWorks(2, 2, 1),
            },
            {
              rewardImg: require("../../assets/img/reward/part3/reward (2).png"),
              works: getWorks(2, 2, 2),
            },
            {
              rewardImg: require("../../assets/img/reward/part3/reward (3).png"),
              works: getWorks(2, 2, 3),
            },
            {
              rewardImg: require("../../assets/img/reward/part3/reward (4).png"),
              works: getWorks(2, 2, 4),
            },
          ],
        },
        // 专业 设计
        {
          groupImg: require("../../assets/img/reward/part5/group.png"),
          rewards: [
            {
              rewardImg: require("../../assets/img/reward/part5/reward (1).png"),
              works: getWorks(1, 1, 1),
            },
            {
              rewardImg: require("../../assets/img/reward/part5/reward (2).png"),
              works: getWorks(1, 1, 2),
            },
            {
              rewardImg: require("../../assets/img/reward/part5/reward (3).png"),
              works: getWorks(1, 1, 3),
            },
          ],
        },
        // 设计 CG类
        {
          groupImg: require("../../assets/img/reward/part6/group.png"),
          rewards: [
            {
              rewardImg: require("../../assets/img/reward/part6/reward (1).png"),
              works: getWorks(1, 3, 1),
            },
            {
              rewardImg: require("../../assets/img/reward/part6/reward (2).png"),
              works: getWorks(1, 3, 2),
            },
            {
              rewardImg: require("../../assets/img/reward/part6/reward (3).png"),
              works: getWorks(1, 3, 3),
            },
          ],
        },
        // 专业 动画
        {
          groupImg: require("../../assets/img/reward/part7/group.png"),
          rewards: [
            {
              rewardImg: require("../../assets/img/reward/part7/reward (1).png"),
              works: getWorks(1, 2, 1),
            },
            {
              rewardImg: require("../../assets/img/reward/part7/reward (2).png"),
              works: getWorks(1, 2, 2),
            },
            {
              rewardImg: require("../../assets/img/reward/part7/reward (3).png"),
              works: getWorks(1, 2, 3),
            },
          ],
        },
      ];

      const example_class_1 = "设计类";
      const example_class_2 = "CG类";
      const example_class_3 = "动画类";

      const example_teacher_rewards = [
        {
          classification: example_class_1,
          name: "王亭亭-成都东软学院",
        },
        {
          classification: example_class_2,
          name: "李京哲-云南艺术学院文华学院",
        },
        {
          classification: example_class_3,
          name: "蔡端懿-四川大学",
        },
      ];
      const example_org_rewards = [
        {
          classification: example_class_1,
          name: "【出来做梦】",
        },
        {
          classification: example_class_2,
          name: "/",
        },
        {
          classification: example_class_3,
          name: "【玩动画】",
        },
      ];
      otherRewards.value = [
        {
          groupImg: require("../../assets/img/reward/part9/group.png"),
          rewardImg: require("../../assets/img/reward/part9/reward.png"),
          entities: example_teacher_rewards,
        },
        {
          groupImg: require("../../assets/img/reward/part10/group.png"),
          rewardImg: require("../../assets/img/reward/part10/reward.png"),
          entities: example_org_rewards,
        },
      ];
    });

    return { commonRewards, otherRewards };
  },
};
</script>

<style lang="less">
html {
  scroll-behavior: smooth;
}
.prize-content {
  font-family: "Noto Sans SC", sans-serif;
  position: relative;
  width: 100%;
  > .main-prize {
    width: fit-content;
    margin: 0 auto;
    box-sizing: border-box;
  }
}
.common-rewards {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 940px;
  padding-top: 116px;

  &.mid {
    padding-top: 86px;
    .query-btn {
      margin-top: 39px;
      background: linear-gradient(180deg, #bce7fa 0%, #9cc5fc 100%);
      font-size: 28px;
      width: 350px;
      height: 64px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      > .btn-img {
        width: 289px;
        height: 28px;
      }
    }
  }
  // 组别名图片
  > .group-img {
    width: 350px;
    height: 105px;
  }
  // 1，2，3奖项
  > .reward {
    width: 100%;
    margin: 60px 0 0 0;
    > .reward-img {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      height: 155px;
      margin: 0 auto 0;
    }
    // 作品
    > .works {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      > .work {
        margin-top: 38px;
      }
      &.works-1 {
        > .work {
          width: 100%;
          font-size: 21px;
        }
      }
      &.works-2 {
        justify-content: space-between;
        > .work {
          width: 48.5%;
          font-size: 14px;
        }
      }
      &.works-5,
      &.works-3 {
        // 3 per row
        > .work {
          margin-right: 36px;
          width: 30%;
          font-size: 12px;

          &:nth-child(3n) {
            margin-right: 0;
          }
        }
      }
    }
  }

  // 组织，教师
  > .entities {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
    > .entity {
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column nowrap;

      width: 288px;
      height: 155px;
      background: url("../../assets/img/reward/other_participant.png");
      background-size: 100% 100%;

      > .classification {
        font-size: 26px;
      }
      > .name {
        text-align: center;
        margin: 18px 20px 0;

        font-size: 23px;
        font-weight: 500;
      }
    }
  }
}
</style>
