<template>
  <div class='other-reward'>

  </div>
</template>

<script>
export default {
  name: 'otherReward'
}
</script>

<style lang='less' scoped>
</style>