<template>
  <div class="work" @click="gotoDetail">
    <img :src="workImg" alt="" class="workImg" />
    <div class="bar">
      <div class="left">
        <img :src="authorImg" alt="" class="head-img" />
        <span>{{ authorName }}</span>
      </div>
      <div class="right">
        <span>{{ workName }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { useRouter } from "vue-router";
export default {
  name: "CommonRewards",
  props: {
    workImg: String,
    workName: String,
    authorImg: String,
    authorName: String,
    id: Number,
    isVideo: Boolean,
  },
  setup(props) {
    const router = useRouter();
    function gotoDetail() {
      router.push({
        name: props.isVideo
          ? "designRewardVideoWorkDetail"
          : "designRewardImageWorkDetail",
        query: {
          id: props.id,
          isAward: true,
        },
      });
    }
    return {
      gotoDetail,
    };
  },
};
</script>
<style lang="less">
.work {
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.01) translateY(-0.5%);
  }
  color: white;
  > .workImg {
    width: 100%;
    aspect-ratio: 1.88;
    border-radius: 16px 16px 0px 0px;
    background-color: white;
    object-fit: cover;
  }
  > .bar {
    width: 100%;
    aspect-ratio: 8.39;
    background-color: #34547e;
    border-radius: 0px 0px 16px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 4.2%;
    span {
      overflow: hidden;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    > .left {
      flex: 1;
      height: 44.6%;
      display: flex;
      align-items: center;
      > .head-img {
        object-fit: contain;
        height: 100%;
        aspect-ratio: 1;
        // background-color: #fffbe1;
        border-radius: 50%;
        margin-right: 0.76em;
        flex-shrink: 0;
      }
    }
    > .right {
      overflow: hidden;
      flex: 1;
      span {
        text-align: right;
      }
    }
  }
}
</style>
