<template>
  <div class="no-reward-work">
    本届2023明基设计大赛（Ani动画高校组）由于整体作品从主题契合，美术，技术等多维考量后，未有符合评委预期的一等佳作。故经评委和组委会反复沟通后，一致决定取消本届大赛高校组一等奖奖项，期待下届有更精彩的作品拔得头筹！
  </div>
</template>
<style lang="less">
.no-reward-work {
  margin-top: 4em;
  width: 100%;
  border: 2px dashed #34547e;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 4.2%;
  box-sizing: border-box;
  font-family: "SSB";
  font-size: 16px;
  line-height: 24px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
